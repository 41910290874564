import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-499fe7d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 0,
  class: "unread"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_BellOutlineIcon = _resolveComponent("BellOutlineIcon")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("span", null, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ]),
    (!_ctx.isLogin)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_TextButton, {
            class: "btn-home",
            onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goHome()))
          }, {
            default: _withCtx(() => [
              _createTextVNode("홈")
            ]),
            _: 1
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("button", {
            class: "alert",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clickAlert && _ctx.clickAlert(...args)))
          }, [
            _createVNode(_component_BellOutlineIcon, { size: 25 }),
            (_ctx.isLogin && _ctx.unreadCount > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.unreadCount), 1)
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
  ]))
}