
import { defineComponent } from "vue";
import BaseHeader1 from "@/components/BaseHeader1.vue";
import DefaultButton from "@/components/common/DefaultButton.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "Home",
  components: { BaseHeader1, DefaultButton },
  data() {
    const store = useStore();
    return { store, os: store.getters["main/os"], redirectTo: "" };
  },
  computed: {
    routePath(): { path: string; query?: { redirect: string } } {
      const routeTo = { path: "/login" };
      if (this.redirectTo) {
        Object.assign(routeTo, {
          query: { redirect: this.redirectTo },
        });
      }
      return routeTo;
    },
  },
  created() {
    this.$flutter.callHandler("hideGnb");
  },
  mounted() {
    const path = this.$route.query as { redirect: string } | null;
    if (path && path?.redirect) {
      this.redirectTo = path.redirect;
    }
  },
  methods: {
    loginKakao() {
      this.$flutter.callHandler("loginKakao");
    },
    loginApple() {
      this.$flutter.callHandler("loginApple");
    },
    loginGoogle() {
      this.$flutter.callHandler("loginGoogle");
    },
  },
});
