
import { defineComponent } from "vue";
import { useStore } from "vuex";
import TextButton from "@/components/common/TextButton.vue";
import BellOutlineIcon from "icons/BellOutline.vue";

const __default__ = defineComponent({
  name: "BaseHeader1",
  components: { TextButton, BellOutlineIcon },
  props: {
    paddingLeft: {
      type: String,
      default: "20px",
    },
    fontFamily: {
      type: String,
      default: "HSSantokki",
    },
    fontWeight: {
      type: String,
      default: "",
    },
  },
  emits: ["openAlert"],
  data() {
    const store = useStore();
    return {
      store,
      unreadCount: 0,
    };
  },
  computed: {
    isLogin: function () {
      return this.store.getters["member/isLogin"];
    },
  },
  methods: {
    goHome() {
      this.$flutter.callHandler("selectGnb", {
        path: "/",
      });
    },
    clickAlert() {
      this.$pushGAEvent("alert_click");
      this.$emit("openAlert");
    },
  },
  mounted() {
    if (this.isLogin) {
      this.$axios.get("/push/alert/unread/count").then((res) => {
        this.unreadCount = res.data.count;
      });
    }
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "cee648a4": (_ctx.paddingLeft),
  "82616c46": (_ctx.fontFamily),
  "47ef9e1e": (_ctx.fontWeight)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__