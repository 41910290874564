
import { defineComponent } from "vue";

const __default__ = defineComponent({
  name: "TextButton",
  emits: ["onClick"],
  props: {
    color: {
      type: String,
      default: "var(--blue1-color)",
    },
    fontSize: {
      type: String,
      default: "16px",
    },
    lineHeight: {
      type: String,
      default: "20px",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "4e84bbe9": (_ctx.color),
  "f8a302ac": (_ctx.fontSize),
  "65a4bd75": (_ctx.lineHeight)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__